const flatArray = (arr) =>
  arr.reduce(
    (total, item) =>
      Array.isArray(item) ? [...total, ...flatArray(item)] : [...total, item],
    []
  );

const removeDuplicates = (array) => {
  return [...new Set(array)];
};

const unpackGalleryImages = (gallery_data) => {
  let unpackedImages = [];
  gallery_data.forEach((item) => {
    unpackedImages = [...unpackedImages, ...item.pageGallery.gallery];
  });

  return unpackedImages;
};

module.exports.removeDuplicates = removeDuplicates;
module.exports.flatArray = flatArray;
module.exports.unpackGalleryImages = unpackGalleryImages;
