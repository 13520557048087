import * as React from "react";
import { graphql } from "gatsby";

import Breadcrumbs from "@components/Breadcrumbs";
import Seo from "@components/Seo";

import { useLocale } from "@context/locale";

import SortingGallery from "@page_components/gallery/SortingGallery";

function GalleryTemplate({ data, pageContext }) {
  const { t, lang } = useLocale();
  const { thumbnails, fullImages } = data;
  const gallery = {
    fullImages: fullImages || null,
    thumbnails: thumbnails || null,
  };
  const { title } = pageContext;
  const breadcrumbs_data = [
    { name: t("Galeria"), href: lang === "en" ? `/en/gallery` : `/galeria` },
    { name: title },
  ];

  return (
    <>
      <Seo
        title={`Galeria - ${title} | Dream Factory`}
        description={`Galeria zdjęć - ${title}`}
      />
      <div className="gallery-page">
        <div className="container">
          <Breadcrumbs data={breadcrumbs_data} />
          <main>
            <h1> Galeria </h1>
            <SortingGallery gallery_data={gallery} context={title} />
          </main>
        </div>
      </div>
    </>
  );
}

export default GalleryTemplate;

export const query = graphql`
  query ($filtr: String!) {
    thumbnails: allWpImage(filter: { slug: { regex: $filtr } }) {
      nodes {
        pageGallery {
          gallery {
            sourceUrl
          }
          category
        }
      }
    }
    fullImages: allWpImage(filter: { slug: { regex: $filtr } }) {
      nodes {
        pageGallery {
          gallery {
            sourceUrl
          }
          category
        }
      }
    }
  }
`;
