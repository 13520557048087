import "./styles.scss";

import React, { useState } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { useLocale } from "@context/locale";
import slugify from "slugify";

import { unpackGalleryImages } from "@utils";

import Gallery from "@components/Gallery";
import Button from "@components/Button";
import FiltrLabel from "@components/FiltrLabel";

function SortingGallery({ gallery_data, context }) {
  const { t, lang } = useLocale();
  let fullImages_data = gallery_data?.fullImages?.nodes;
  let thumbnails_data = gallery_data?.thumbnails?.nodes;

  if (context === "Salon") {
    fullImages_data = gallery_data?.fullImages?.nodes.filter(
      (item) => item.pageGallery.category !== "Zespół"
    );
    thumbnails_data = gallery_data?.thumbnails?.nodes.filter(
      (item) => item.pageGallery.category !== "Zespół"
    );
  }

  const images_data = {
    fullImages: unpackGalleryImages(fullImages_data),
    thumbnails: unpackGalleryImages(thumbnails_data),
  };

  const handleSelect = (e) => {
    navigate(`/galeria/${e.target.value}/#!`);
  };

  //* WP CMS DATA */
  const data = useStaticQuery(graphql`
    {
      categories: allWpImage(
        filter: { pageGallery: { category: { eq: "Zespół" } } }
      ) {
        nodes {
          title
          slug
          pageGallery {
            titleTranslation
          }
        }
      }
      procedures: allWpImage(
        filter: { pageGallery: { category: { eq: "Salon" } } }
      ) {
        nodes {
          title
          slug
          pageGallery {
            titleTranslation
          }
        }
      }
    }
  `);

  //* SORT OPTIONS */
  let { categories, procedures } = data;
  categories = categories?.nodes;
  procedures = procedures?.nodes;

  const [numberPhotos, setNumberPhotos] = useState(9);
  const [currentCategory, setCurrentCategory] = useState(context);
  const [currentProcedure, setCurrentProcedure] = useState(context);

  return (
    <div className="sorting-gallery">
      {categories.length > 0 && (
        <div className="sorting-gallery__category">
          <p>{t("Zobacz zdjęcia z wybranej kategorii")}</p>
          <div className="sorting-gallery__label-list">
            <FiltrLabel
              name={t("Wszystkie")}
              checked={currentCategory === "Wszystkie"}
              link={lang === "en" ? "/en/gallery/#!" : "/galeria/#!"}
            />
            <FiltrLabel
              name="Salon"
              checked={
                currentCategory !== t("Zespół") &&
                currentCategory !== "Wszystkie"
              }
              link={
                lang === "en" ? "/en/gallery/salon/#!" : "/galeria/salon/#!"
              }
            />

            {categories.map((category) => {
              const title =
                lang === "en"
                  ? category.pageGallery.titleTranslation
                  : category.title;
              return (
                <FiltrLabel
                  name={
                    lang === "en"
                      ? category.pageGallery.titleTranslation
                      : category.title
                  }
                  handleChecked={() => setCurrentCategory(category.title)}
                  key={category.title}
                  checked={currentCategory === title}
                  link={
                    lang === "en"
                      ? `/en/gallery/${slugify(
                          category.pageGallery.titleTranslation.toLowerCase()
                        )}/#!`
                      : `/galeria/${category.slug}/#!`
                  }
                />
              );
            })}
          </div>
        </div>
      )}

      {procedures.length > 0 && currentCategory !== "Zespół" && (
        <div className="sorting-gallery__procedures">
          <p>{t("Zobacz zdjęcia z wybranego zabiegu")}</p>
          <div className="sorting-gallery__label-list">
            {procedures.map((procedure) => {
              const title =
                lang === "en"
                  ? procedure.pageGallery.titleTranslation
                  : procedure.title;
              return (
                <FiltrLabel
                  name={
                    lang === "en"
                      ? procedure.pageGallery.titleTranslation
                      : procedure.title
                  }
                  handleChecked={() => setCurrentProcedure(title)}
                  key={title}
                  checked={currentProcedure === title}
                  link={
                    lang === "en"
                      ? `/en/gallery/${slugify(
                          procedure.pageGallery.titleTranslation.toLowerCase()
                        )}/#!`
                      : `/galeria/${procedure.slug}/#!`
                  }
                />
              );
            })}
          </div>
          <select
            name="procedures"
            id="procedures"
            className="sorting-gallery__select"
            onChange={handleSelect}
          >
            <option value="" disabled selected>
              {currentProcedure}
              &nbsp;
            </option>
            {procedures.map((procedure) => (
              <option value={procedure.slug}>{procedure.title}</option>
            ))}
          </select>
        </div>
      )}

      <Gallery data={images_data} limit={numberPhotos} />
      {images_data?.fullImages?.length > numberPhotos && (
        <Button
          className="sorting-gallery__button"
          onClick={() => setNumberPhotos(numberPhotos + 9)}
        >
          {t("Pokaż więcej zdjęć")}
        </Button>
      )}
    </div>
  );
}

export default SortingGallery;
